import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

const Store3 = () => {
  const { pathname } = useLocation()
  return (
    <div className='store-welcome'>
      <h1 className='store-name'>Bienvenido a {pathname} </h1>
      <p className='store-description'>Nuestros Productos </p>
      {/* Puedes agregar más contenido aquí, como imágenes, botones, etc. */}
    </div>
  )
}

export default Store3
