import React, { useState } from 'react'

const Home = () => {
  const [subdomain, setSubDomain] = useState([
    window.location.host.split('.')[0],
    window.location.host.split('.')[1],
  ])
  const [domain, setDomain] = useState('')

  return (
    <div>
      Home Princial sin redireccion sin www {subdomain[0]} ||| con www{' '}
      {subdomain[1]}{' '}
    </div>
  )
}

export default Home
