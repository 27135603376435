import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import Redireccion from './pages/Redireccion'
import Home from './pages/Home'
import Store3 from './pages/store3'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='' element={<Home />} />
        {/* <Route path=':storeName' element={<Redireccion />} /> */}
        <Route path='/store3' element={<Store3 />} />

        {/* Add other routes as needed */}
      </Routes>
    </BrowserRouter>
  )
}

export default App
